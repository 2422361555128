<template>

	<div>
		<template v-if="!isAnswerQuiz">
			<div class="wrap p-mypage-verify">
				<layout-header title="부모님 인증 하기" btn-right="close"></layout-header>
				<div class="container">
					<div class="content">
						<div class="img-wrap">
							<img alt="" src="/assets/images/mypage/img_mypage_verify.png">
						</div>
						<div class="verify-form">
							<form @submit.prevent="chkAnswer">
								<div class="verify-question">
									<span>{{ quizArr[0] }} x {{ quizArr[1] }} =</span>
									<input v-model="quizAnswer" class="inp-answer" type="number">
								</div>
								<div class="button-area">
									<a class="btn-member" type="submit" @click="chkAnswer">확인</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template v-else-if="isAnswerQuiz">
			<div class="wrap p-mypage-account">
				<layout-header title="부모페이지 - 회원정보" btn-left="logo" btn-right="close"></layout-header>
				<div class="container">
					<div class="content">
						<div class="account-info">
							<dl>
								<dt>계정</dt>
								<dd>
									<p class="account">
										<template v-if="coAccountVO.snsService == 'kakao'">
											<i class="ico-kakao"></i>
										</template>
										<template v-if="coAccountVO.snsService == 'apple'">
											<i class="ico-apple"></i>
										</template>
										<template v-if="coAccountVO.snsService == 'naver'">
											<i class="ico-naver"></i>
										</template>
										{{
											coAccountVO.coEmailId
										}}
									</p>
									<button class="btn-member" type="button" @click="doLogout">로그아웃</button>
								</dd>
							</dl>
							<dl v-if="coAccountVO.snsService == '' || coAccountVO.snsService == null">
								<dt>비밀번호 변경</dt>
								<dd>
									<button class="btn-member blue modal-open" data-pop="changePassword" type="button">
										비밀번호
										변경
									</button>
								</dd>
							</dl>
							<dl>
								<dt>자녀관리</dt>
								<dd>
									<button class="btn-member" type="button">
										<router-link :to="{name: 'profileManage'}">자녀관리</router-link>
									</button>
								</dd>
							</dl>
							<dl>
								<dt>회원탈퇴</dt>
								<dd>
									<button class="btn-member" type="button" @click="out">탈퇴하기</button>
									<p class="guide-text">
										부모계정 탈퇴 시,<br>자녀회원도 서비스 이용이 불가합니다.</p>
										<!--자녀회원이 정회원인 경우에는<br> 부모계정 탈퇴가 불가합니다.</p>-->
								</dd>
							</dl>
						</div>
						<div class="guide-link">
							<a @click="mailTo('soda2779@naver.com')">건의 및 불편 신고</a>
							<router-link :to="{ path: '/pages/termsofservice'}" target="_blank">이용약관</router-link>
							<router-link :to="{ path: '/pages/privacypolicy'}" target="_blank">개인정보 수집 및 이용</router-link>
						</div>
					</div>
				</div>
			</div>
			<div id="changePassword" class="modal modal-common m-password">
				<div class="modal-content">
					<h3>비밀번호 변경</h3>
					<form @submit.prevent="changePassword">
						<fieldset class="join-field">
							<legend>비밀번호 변경</legend>
							<div class="form-row">
								<label for="password" style="font-size: 16px">기존 비밀번호</label>
								<input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
									   type="password" v-model="oldPasswd">
							</div>
							<div class="form-row">
								<label for="password" style="font-size: 16px">새 비밀번호</label>
								<input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
									   type="password" v-model="newPasswd">
							</div>
							<div class="form-row">
								<label for="password" style="font-size: 16px">새 비밀번호 확인</label>
								<input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
									   type="password" v-model="newPasswdChk">
							</div>
						</fieldset>
						<div class="button-area">
							<button class="btn-member gray sm modal-close" type="button">취소</button>
							<button class="btn-member purple sm modal-close" type="submit">변경</button>
						</div>
					</form>
				</div>
			</div>
		</template>

	</div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from "jquery";

export default {
	name: "accountMyPage",
	components: {LayoutHeader},
	data() {
		const coEmailId = this.getLoginMainId()

		return {

			coEmailId,
			quizArr: Object,
			quizAnswer: '',
			isAnswerQuiz: false,
			coAccountVO: {},
			isPossibleOut: false,
			oldPasswd: '',
			newPasswd: '',
			newPasswdChk: '',

		}
	},
	created() {
		this.requireLoginMainWithoutAlert()
		this.quizArr = this.commonUtils.createRandomQuiz([2, 9], [2, 9])
		this.getAccountInfo()
	},
	mounted() {
		let observer = new MutationObserver(() => {

			$('.modal-open').on('click', function () {
				var attr = $(this).attr('data-pop');
				$('#' + attr).fadeIn()
			})

			$('.modal-close').on('click', function () {
				$('.modal').fadeOut()
			})

		})
		observer.observe(document.querySelectorAll('.content')[1], {
			attributes: true,
			childList: true,
			characterData: true
		})
	},
	methods: {
		chkAnswer(e) {
			e.preventDefault()
			this.isAnswerQuiz = this.quizAnswer * 1 === this.quizArr[2]
		},

		getAccountInfo() {

			this.$eventBus.$emit("startProgress")

			const self = this

			this.axiosCaller.get(self, self.APIs.CO_ACCOUNT + '/', {
				coCode: self.webUtils.getSubdomain(),
				coEmailId: self.getLoginMainId()
			}, (res) => {

				self.coAccountVO = res.data.coAccountVO
				self.getIsPossibleOut(res.data.coAccountVO)
				self.$eventBus.$emit("doneProgress", 500)

			})
		},

		out() {
			const self = this

			// 2022.07.13 : 자녀계정 정회원 체크 없이 탈퇴
			this.$router.push({
				name: "confirmOut",
				query: {
					isParent: self.coAccountVO.isParent
				}
			})

			/*if (this.isPossibleOut) {
				this.$router.push({
					name: "confirmOut",
					query: {
						isParent: self.coAccountVO.isParent
					}
				})
			} else {
				this.$swal("자녀 계정 중 정회원 존재로 인해 불가능", "", "warning", "확인")
			}*/

		},

		getIsPossibleOut() {

			const _ = window._
			const self = this

			this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getChild', {
				coEmailId: self.coEmailId
			}, (res) => {

				let authEmailArr = []

				_.forEach(res.data.profileAccountList, (v, i) => {
					authEmailArr[i] = v.coaccountVO.authEmail
				})

				self.isPossibleOut = this.arrayUtils.isEqualAllItemInArray(authEmailArr) && authEmailArr[0] === false || authEmailArr.length === 0

			})
		},

		changePassword(e) {
			const self = this

			this.axiosCaller.patch4MainSession(this, this.APIs.CO_ACCOUNT + "/changePassword", {
				oldPasswd: this.oldPasswd,
				newPasswd: this.newPasswd,
				newPasswdChk: this.newPasswdChk,
			}, (res) => {
				const result = res.data;

				if (result.status === "ok") {
					self.swalUtils.gritter("비밀번호 변경완료", "다음 로그인시 부터 적용됩니다.", "success", 3000)
				} else {
					self.swalUtils.gritter("변경취소", result.msg, "error", 3000)
				}
			})
		},

		mailTo(email) {
			this.appMailTo(email)
		}
	},
	watch: {
		'isAnswerQuiz': function () {

			$('.modal-open').on('click', function () {
				var attr = $(this).attr('data-pop');
				$('#' + attr).fadeIn();
			})

			$('.modal-close').on('click', function () {
				$('.modal').fadeOut();
			})

		}
	}

}
</script>

<style scoped>

</style>